.about-section {
  background-color: var(--background-color-light);
  padding-top: 5.6rem;
}

.about__content-container {
  padding: 1rem 2.4rem;
}

.about__content__punchline {
  color: var(--secondary-color);
  text-align: center;
  font-size: 1.8rem;
}

.about__content {
  color: #202020;
  max-width: 100rem;
  padding: .5rem 0;
  font-size: 2rem;
  line-height: 1.2;
}

.about__process-container {
  justify-content: center;
  align-items: center;
  width: 120rem;
  padding: 2.4rem;
  display: flex;
  transform: translateX(-15rem);
}

.tertiary__header.about__process {
  padding-top: 1.2rem;
  padding-bottom: 0;
}

.about__card-container {
  background-color: #fff9db;
  border-radius: 2.4rem;
  justify-content: center;
  align-items: center;
  width: 15rem;
  padding: 2.4rem;
  display: flex;
}

.about__card-container.launch {
  background-color: #c3fae8;
  border-radius: 50%;
  width: 15rem;
  height: 20rem;
}

.about__card-content {
  text-align: center;
  font-size: 2.4rem;
}

.about__card-icon {
  font-size: 3.2rem;
}

.about__card-icon.launch {
  font-size: 5.2rem;
}

.about__card__text-container {
  text-transform: capitalize;
}

.about__card__text {
  font-size: 2.2rem;
  font-weight: bold;
}

.about__next {
  color: var(--primary-color);
  font-size: 2.8rem;
}

.about__skill__container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.about__skill__lists {
  text-transform: uppercase;
  color: var(--dark-color-tint);
  justify-content: center;
  gap: 2.4rem;
  font-size: 2.4rem;
  font-weight: bold;
  list-style: none;
  display: flex;
}

/*# sourceMappingURL=index.d088662d.css.map */
