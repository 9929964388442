@import "variables.css";

.about-section {
  padding-top: 5.6rem;
  background-color: var(--background-color-light);
}

.about__content-container {
  padding: 1rem 2.4rem;
}

.about__content__punchline {
  color: var(--secondary-color);
  text-align: center;
  font-size: 1.8rem;
}

.about__content {
  font-size: 2rem;
  max-width: 100rem;
  color: #202020;
  padding: 0.5rem 0;
  line-height: 1.2;
}

.about__process-container {
  padding: 2.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120rem;

  transform: translateX(-15rem);
}

.tertiary__header.about__process {
  padding-top: 1.2rem;
  padding-bottom: 0;
}

.about__card-container {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #fff9db;
  width: 15rem;
  padding: 2.4rem;
  border-radius: 2.4rem;
}

.about__card-container.launch {
  background-color: #c3fae8;
  width: 15rem;
  height: 20rem;
  border-radius: 50%;
}

.about__card-content {
  font-size: 2.4rem;
  text-align: center;
}

.about__card-icon {
  font-size: 3.2rem;
}

.about__card-icon.launch {
  font-size: 5.2rem;
}

.about__card__text-container {
  text-transform: capitalize;
}

.about__card__text {
  font-size: 2.2rem;
  font-weight: bold;
}

.about__next {
  font-size: 2.8rem;
  color: var(--primary-color);
}

.about__skill__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about__skill__lists {
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 2.4rem;
  text-transform: uppercase;
  font-size: 2.4rem;
  font-weight: bold;
  color: var(--dark-color-tint);
}
